<template>
  <div class=" subBrand brandClass applicationBrand">
    <section-title class="title" title="一站式服务流程" />
    <div class="content">
      <div class="summary" :style="{padding: `${SubBrand_Application.length > 6 ? '45px 0' : '71px 0'}`}">
        <div class="item" v-for="(item, index) in SubBrand_Application" :class="{active: activeIndex === index}"
             @mouseenter="setActiveIndex(index)"
        >
          <div class="logoWrapper">
            <div class="logo">
              <img :src="activeIndex === index? item.activeLogo: item.logo"
                   style="width: 100%;height: 100%"
                   alt="">
            </div>
            <div class="logoTitle" :class="{logoTitleActive: activeIndex === index}">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="logoPicture">
        <img :src="SubBrand_Application[activeIndex].picture"
             style="width: 100%; height: 100%"
             alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import SectionTitle from '@/views/Abroad/modules/SectionTitle.vue'

  export default {
    name: 'Flow',
    components: { SectionTitle },
    data () {
      return {
        activeIndex: 0,
        SubBrand_Application: [
          {
            title: '选校方案',
            logo: `${this.$domain}/image/ow/pc/abroad/v2-de3eedc958bd83269e6a71a158f9dabe.png`,
            activeLogo:
              `${this.$domain}/image/ow/pc/abroad/v2-7ec9f84477b89e5356b6317b18a40460.png`,
            picture: `${this.$domain}/image/ow/pc/abroad/v2-f11e6a59dcd7988a5f2ff4f4b44e49a9.png`,
          },
          {
            title: '文书辅导',
            logo: `${this.$domain}/image/ow/pc/abroad/v2-89981a0c7fe2389604e8e84117652d94.png`,
            activeLogo:
              `${this.$domain}/image/ow/pc/abroad/v2-fd29130c038dc1b2e2d31d2c07b574d7.png`,
            picture: `${this.$domain}/image/ow/pc/abroad/v2-6eb5b1161348ade6fecd314e6886f75e.png`,
          },
          {
            title: '推荐信辅导',
            logo: `${this.$domain}/image/ow/pc/abroad/v2-ee36cb4ce05178f0c26175bc7c0557f0.png`,
            activeLogo:
              `${this.$domain}/image/ow/pc/abroad/v2-b0a3443584deb231dc5a9ad602af1fb2.png`,
            picture: `${this.$domain}/image/ow/pc/abroad/v2-68fc12db07fd155a0ff6e07d44375ce1.png`,
          },
          {
            title: '项目申请',
            logo: `${this.$domain}/image/ow/pc/abroad/v2-cb29137e9b944024a9d833777a88ec4e.png`,
            activeLogo:
              `${this.$domain}/image/ow/pc/abroad/v2-25f3abc3dc7aecc0d254e6c838f4dbbd.png`,
            picture: `${this.$domain}/image/ow/pc/abroad/v2-7ef33ddbccbea04fda9d9821e5374d93.png`,
          },
          {
            title: '面试辅导',
            logo: `${this.$domain}/image/ow/pc/abroad/v2-fbefa5758cabe1ecebd039d89d8f854e.png`,
            activeLogo:
              `${this.$domain}/image/ow/pc/abroad/v2-a0ff74f2a9d666f258178331bb5df5df.png`,
            picture: `${this.$domain}/image/ow/pc/abroad/v2-ad90c1484c29d9997de72e7550ada795.png`,
          },
          {
            title: '成行后',
            logo: `${this.$domain}/image/ow/pc/abroad/v2-094b43bd08786eb00d98c68105a3cf92.png`,
            activeLogo:
              `${this.$domain}/image/ow/pc/abroad/v2-31586e5f9f892cc3e6e113b86607cae3.png`,
            picture: `${this.$domain}/image/ow/pc/abroad/v2-b52eded4b934435190197aa32cc48d6c.png`,
          },
        ]
      }
    },
    methods: {
      setActiveIndex ( index ) {
        this.activeIndex = index
      }
    }
  }
</script>

<style lang="less" scoped>
  .subBrand {
    margin-top: 60px;
    background-color: #fff;
  }

  .content {
    border-radius: 30px;
    background: rgba(255, 255, 255, 0);
    width: 1050px;
    height: 638px;
    margin: 63px auto 0;
    display: flex;
    background-size: 300px 229px;
    background-position: top -10px right -100px;

    .summary {
      width: 230px;
      height: 351px;
      border-radius: 30px 0px 0px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 24px;
      flex-wrap: wrap;
      padding: 120px 0 !important;
      margin-right: 40px;



      .item {
        width: 230px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .item:hover {
        .logoTitle {
          color: #fff;
        }
      }

      .active {
        background: linear-gradient(to right, #0062FF, #52A0FF);
        border-radius: 8px;
      }

      .logoWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 230px;
        height: 30px;
        line-height: 30px;

        .logo {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          margin-left: 16px;
        }

        .logoTitle {
          font-size: 15px;
          font-weight: 400;
          color: #2E3238;
        }

      }

    }

    .logoTitleActive {
      color: #fff !important;
    }

    .logoPicture {
      width: 797px;
      height: 569px;
      border-radius: 32px;
    }

  }
</style>
