<template>
  <div class="subBrand planningBrand" :style="{backgroundImage: `url(${$domain}/image/ow/pc/abroad/v2-0701ddbf93e76de3b86121530a1f689a.png)`}">
    <SectionTitle title="服务内容"></SectionTitle>
    <div class="content">
      <div class="summary"
           :style="{padding: `${SubBrand_Planning.length > 6 ? '45px 0' : '71px 0'}`}"
      >
        <div class="item" v-for="(item,index) in SubBrand_Planning" :key="item.title"
             @mouseenter="setActiveIndex(index)"
             :class="activeIndex === index?'active':''">
          <div class="logoWrapper">
            <div class="logo">
              <img :src="activeIndex === index?item.activeLogo:item?.logo"
                   style="width: 100%;height: 100%"
                   alt="">
            </div>
            <div class="logoTitle" :class="activeIndex===index?'logoTitleActive': ''">
              {{ item?.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="logoPicture">
        <img :src="SubBrand_Planning[activeIndex].picture" alt=""
             style="width: 100%;height: 100%"
        >
      </div>
    </div>
  </div>
</template>

<script>
  import SectionTitle from '@/views/Abroad/modules/SectionTitle.vue'

  export default {
    name: 'ServiceContent',
    components: { SectionTitle },
    props: {},
    data () {
      return {
        SubBrand_Planning: [
          {
            title: '服务启动',
            logo: `${this.$domain}/image/ow/pc/abroad/v2-5536975e61090f5f8cd7ffc6cf13821c.png`,
            activeLogo:
              `${this.$domain}/image/ow/pc/abroad/v2-238e90c6491f26ab14a67a0f461225b4.png`,
            picture: `${this.$domain}/image/ow/pc/abroad/v2-72b3097e4ee684cceee1f66bb52345fd.png`,
          },
          {
            title: '专业诊断',
            logo: `${this.$domain}/image/ow/pc/abroad/v2-aa815d86aa2b3f432893dfd3a0a86535.png`,
            activeLogo:
              `${this.$domain}/image/ow/pc/abroad/v2-0281cc5a1daec1432b11a8aa9001fefd.png`,
            picture: `${this.$domain}/image/ow/pc/abroad/v2-85587a75099969e50d2cdeb8b0aad228.png`,
          },
          {
            title: '标化考试',
            logo: `${this.$domain}/image/ow/pc/abroad/v2-dda2c9269cece09e69c8552474ae331c.png`,
            activeLogo:
              `${this.$domain}/image/ow/pc/abroad/v2-bc668295a53cd9338d163a6e812abd52.png`,
            picture: `${this.$domain}/image/ow/pc/abroad/v2-aa0b3d65b43fd20afbe51c34db464c8c.png`,
          },
          {
            title: '软性背景提升',
            logo: `${this.$domain}/image/ow/pc/abroad/v2-2fac07ba3951fa3dcd0872381a23df7e.png`,
            activeLogo:
              `${this.$domain}/image/ow/pc/abroad/v2-0281cc5a1daec1432b11a8aa9001fefd.png`,
            picture: `${this.$domain}/image/ow/pc/abroad/v2-dcd2d20a46113a18e85b0190efaaeb60.png`,
          },
          {
            title: '服务内部质控',
            logo: `${this.$domain}/image/ow/pc/abroad/v2-cb98b1211827c4189b6a4de6110e9f51.png`,
            activeLogo:
              `${this.$domain}/image/ow/pc/abroad/v2-e1b672a992fa3e897af7e021da32e596.png`,
            picture: `${this.$domain}/image/ow/pc/abroad/v2-cbf099103c5a776a1690b4b4dd651e13.png`,
          },
          {
            title: '考试规划及跟踪指导',
            logo: `${this.$domain}/image/ow/pc/abroad/v2-29e8382f103bfc650f526fb21253a147.png`,
            activeLogo:
              `${this.$domain}/image/ow/pc/abroad/v2-ed9a04d9789864d8285704b02f180d26.png`,
            picture: `${this.$domain}/image/ow/pc/abroad/v2-59aee9c3e9f65f628e30afd7769dec97.png`,
          },
        ],
        activeIndex: 0
      }
    },
    methods: {
      setActiveIndex ( index ) {
        this.activeIndex = index
      }
    }
  }
</script>

<style lang="less" scoped>
  .subBrand {
    margin-top: 60px;
    background-color: #fff;
  }

  .content {
    border-radius: 30px;
    background: rgba(255, 255, 255, 0);
    width: 1050px;
    height: 638px;
    margin: 63px auto 0;
    display: flex;
    background-size: 300px 229px;
    background-position: top -10px right -100px;

    .summary {
      width: 230px;
      height: 351px;
      border-radius: 30px 0px 0px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 24px;
      flex-wrap: wrap;
      padding: 120px 0 !important;
      margin-right: 40px;


      .item {
        width: 230px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .item:hover {
        .logoTitle {
          color: #fff;
        }
      }

      .active {
        background: linear-gradient(to right, #0062FF, #52A0FF);
        border-radius: 8px;
      }

      .logoWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 230px;
        height: 30px;
        line-height: 30px;

        .logo {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          margin-left: 16px;
        }

        .logoTitle {
          font-size: 15px;
          font-weight: 400;
          color: #2E3238;
        }

      }

    }

    .logoTitleActive {
      color: #fff !important;
    }

    .logoPicture {
      width: 797px;
      height: 569px;
      border-radius: 32px;
    }

  }
</style>
