import DetailBanner from "@/components/DetailBanner/DetailBanner.vue";
import Area from "./modules/Area.vue";
import ServiceContent from "./modules/ServiceContent.vue";
import ServiceFeatures from "./modules/ServiceFeatures.vue";
import Team from "./modules/Team.vue";
import Flow from "./modules/Flow.vue";
import FloatBar from "@/components/FloatBar/FloatBar.vue";
import FloatAds from '@/components/FloatAds/FloatAds.vue'
import OfferSection from '@/views/Abroad/modules/offer/OfferSection.vue'

export default {
  components: {
    OfferSection,
    DetailBanner,
    Area,
    ServiceContent,
    ServiceFeatures,
    Team,
    Flow,
    FloatBar,
    FloatAds
  },
  data() {
    return {
      isShowQrcode: false,
      banner: {
        title: "为什么选择趴趴留学？",
        subTitle:
          "一站式留学服务，近10年深耕留学培训行业，签约服务学员超5000人，98.5% 申请成功率，累计斩获 2w+枚offer",
      },
      imageDomain:  this.$domain + 'image/ow/pc/abroad/'
    };
  },
  mounted() {},
  methods: {},
};
